import React from "react"
import styled from "styled-components"
import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"
import { Howl } from "howler"

const StyledBottomBar = styled.nav`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  color: var(--off-white);
  z-index: 9999;
  padding: 10px;
  pointer-events: none;

  display: flex;
  justify-content: space-between;

  * {
    pointer-events: auto;
  }
`

export default function BottomBar() {
  const data = useGameStore((s) => s.data)
  const sharedData = useLocaleCheck(data?.shared)
  const music = React.useRef<Howl>(
    new Howl({ src: "/music.mp3", loop: true, html5: true })
  )
  const [musicPlaying, setMusicPlaying] = React.useState<boolean>(false)

  function handleMusicOn() {
    localStorage.setItem("audio", "on")
    music.current.play()
    setMusicPlaying(true)
  }

  function handleMusicOff() {
    localStorage.setItem("audio", "off")
    music.current.pause()
    setMusicPlaying(false)
  }

  function handleMusicButton() {
    if (music.current.playing()) {
      handleMusicOff()
    } else {
      handleMusicOn()
    }
  }

  function handleMusicEvent() {
    if (localStorage.getItem("audio") === "off") {
      handleMusicOff()
    } else {
      handleMusicOn()
    }
  }

  React.useEffect(() => {
    document.addEventListener("playmusic", handleMusicEvent)
    return () => document.removeEventListener("playmusic", handleMusicEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledBottomBar>
      <div
        className="bb__button"
        role="button"
        onClick={handleMusicButton}
        style={{
          textDecoration: musicPlaying ? "none" : "line-through",
        }}
      >
        {sharedData?.music}
      </div>
      <div className="bb__button">
        <a href={sharedData?.tcLinkAddress} target="_blank" rel="noreferrer">
          {sharedData?.tcTerms}
        </a>
      </div>
    </StyledBottomBar>
  )
}
