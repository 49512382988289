import React from "react"
import ContinueShoppingButton from "../common/ContinueShoppingButton"
import Popup from "../common/Popup"
import Portal from "../common/Portal"
import lostImage from "../../images/red-herring.png"

interface Props {
  title?: string
  text?: string
}

export default function Notice({ title, text }: Props) {
  return (
    <Portal where="portal">
      <Popup>
        <h1>{title}</h1>
        <img alt="" src={lostImage} />
        <p>{text}</p>
        <ContinueShoppingButton />
      </Popup>
    </Portal>
  )
}
