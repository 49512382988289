import { create } from "zustand"

interface GameStore {
  data: any
  setData: (d: any) => void

  id: string
  setId: (id: string) => void

  open: boolean
  setOpen: (o: boolean) => void

  prize: string | null
  setPrize: (p: string | null) => void

  prevPrize: string | null
  setPrevPrize: (p: string | null) => void

  backupPrize?: string | null
  setBackupPrize: (bp: string | null) => void

  score: number
  setScore: (score: number) => void

  tries: number
  setTries: (t: number | string) => void

  difficulty: boolean
  setDifficulty: (d: boolean) => void

  time: number
  setTime: (t: number | string) => void

  lang: string
  setLang: (l: string) => void

  locale: string
  setLocale: (l: string) => void
}

export const useGameStore = create<GameStore>()((set) => ({
  data: null,
  setData: (d) => set((_state) => ({ data: d })),

  id: "",
  setId: (id) => set((_state) => ({ id: id })),

  open: true,
  setOpen: (o) => set((_state) => ({ open: o })),

  prize: "",
  setPrize: (p) => set((_state) => ({ prize: p })),

  prevPrize: "",
  setPrevPrize: (p) => set((_state) => ({ prevPrize: p })),

  setBackupPrize: (bp) => set((_state) => ({ backupPrize: bp })),

  score: 0,
  setScore: (s) => set((_state) => ({ score: s })),

  tries: 3,
  setTries: (t) => set((_state) => ({ tries: parseInt(`${t}`, 10) })),

  difficulty: true,
  setDifficulty: (d) => set((_state) => ({ difficulty: d })),

  time: 30,
  setTime: (t) => set((_state) => ({ time: parseInt(`${t}`, 10) })),

  lang: "en",
  setLang: (l) => set((_state) => ({ lang: l })),

  locale: "uk",
  setLocale: (l) => set((_state) => ({ locale: l })),
}))
