import { useEffect } from "react"
import { useGameStore } from "../../state"

enum Locale {
  Item = "locale",
  UK = "uk",
  US = "us",
  UAE = "uae",
}

enum Language {
  Item = "lang",
  EN = "en",
  DE = "de",
  ES = "es",
  FR = "fr",
  IT = "it",
  AR = "ar",
}

export const useLocaleSetter = () => {
  const setLang = useGameStore((s) => s.setLang)
  const setLocale = useGameStore((s) => s.setLocale)

  function handleLocaleUpdate(params: URLSearchParams) {
    if (localStorage.getItem(Locale.Item)) {
      const l = localStorage.getItem(Locale.Item)
      setLocale(l || Locale.UK)
    } else if (params.get(Locale.Item) === Locale.US) {
      setLocale(Locale.US)
      localStorage.setItem(Locale.Item, Locale.US)
    } else if (params.get(Locale.Item) === Locale.UAE) {
      setLocale(Locale.UAE)
      localStorage.setItem(Locale.Item, Locale.UAE)
    } else {
      setLocale(Locale.UK)
      localStorage.setItem(Locale.Item, Locale.UK)
    }
  }

  function handleLangUpdate(params: URLSearchParams) {
    if (localStorage.getItem(Language.Item)) {
      const l = localStorage.getItem(Language.Item)
      setLang(l || Language.EN)
    } else if (params.get(Language.Item) === Language.DE) {
      setLang(Language.DE)
      localStorage.setItem(Language.Item, Language.DE)
    } else if (params.get(Language.Item) === Language.ES) {
      setLang(Language.ES)
      localStorage.setItem(Language.Item, Language.ES)
    } else if (params.get(Language.Item) === Language.FR) {
      setLang(Language.FR)
      localStorage.setItem(Language.Item, Language.FR)
    } else if (params.get(Language.Item) === Language.IT) {
      setLang(Language.IT)
      localStorage.setItem(Language.Item, Language.IT)
    } else if (params.get(Language.Item) === Language.AR) {
      setLang(Language.AR)
      localStorage.setItem(Language.Item, Language.AR)
    } else {
      setLang(Language.EN)
      localStorage.setItem(Language.Item, Language.EN)
    }
  }

  useEffect(() => {
    let params = new URLSearchParams(document.location.search)

    handleLocaleUpdate(params)
    handleLangUpdate(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
