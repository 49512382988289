/* eslint-disable react-hooks/exhaustive-deps */
import "./styles/reset.css"
import "./styles/fonts.css"
import React from "react"
import { GlobalStyles } from "./styles/global"
import { useCalculateVh } from "./utils/common/useCalculateVh"
import { Route, Routes, useLocation } from "react-router-dom"
import Play from "./components/play/Play"
import Home from "./components/home/Home"
import Prize from "./components/prize/Prize"
import { getApiContent } from "./utils/api"
import { useGameStore } from "./state"
import { useLocaleSetter } from "./utils/localisation/useLocaleSetter"
import { useSettingsCheck } from "./utils/localisation/useLocaleCheck"
import { resetCookiesCheat } from "./utils/cookies"
import Decorations from "./components/common/Decorations"
import BottomBar from "./components/common/BottomBar"

export default function App() {
  const [error, setError] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const data = useGameStore((s) => s.data)
  const lang = useGameStore((s) => s.lang)
  const setData = useGameStore((s) => s.setData)
  const setDifficulty = useGameStore((s) => s.setDifficulty)
  const setOpen = useGameStore((s) => s.setOpen)
  const setTime = useGameStore((s) => s.setTime)
  const setTries = useGameStore((s) => s.setTries)

  const location = useLocation()

  useCalculateVh()
  useLocaleSetter()

  const settingsData = useSettingsCheck(data?.settings)

  async function getContent() {
    try {
      const res = await getApiContent()
      setData(res?.data?.data)
      process.env.NODE_ENV === "development" && console.log(res)
    } catch (error) {
      console.error(error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getContent()
  }, [])

  React.useEffect(() => {
    if (!loading) {
      setDifficulty(settingsData?.difficulty === "1" ? true : false)
      setOpen(settingsData?.on === "1" ? true : false)
      setTime(settingsData?.time)
      setTries(settingsData?.tries)
      resetCookiesCheat()
    }
  }, [loading])

  if (error) {
    return <p>An error has occured, please refresh the page and try again.</p>
  }

  return (
    <>
      <GlobalStyles />
      <div
        className="App"
        style={{ opacity: loading ? 0 : 1 }}
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <Decorations open={location.pathname !== "/"} />
        <Routes>
          <Route index element={<Home />} />
          <Route path="play" element={<Play />} />
          <Route path="prize" element={<Prize />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <BottomBar />
      </div>
    </>
  )
}
