/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import styled from "styled-components"
import { useGameStore } from "../../state"
import {
  useLocaleCheck,
  useSettingsCheck,
} from "../../utils/localisation/useLocaleCheck"
import { checkTriesLeft } from "../../utils/cookies"
import { Navigate } from "react-router-dom"
import { getApiEnd } from "../../utils/api"
import Layout from "./Layout"

export enum PrizeKeys {
  Lost = "LOST",
  Gold = "PLAY1",
  Silver = "PLAY2",
  Bronze = "PLAY3",
}

const StyledPrize = styled.div`
  min-height: var(--100vh);
  transition: all 0.8s ease;
`

export default function Prize() {
  const [loading, setLoading] = React.useState<boolean>(true)

  const data = useGameStore((s) => s.data)
  const id = useGameStore((s) => s.id)
  const prize = useGameStore((s) => s.prize)
  const setPrize = useGameStore((s) => s.setPrize)
  const prevPrize = useGameStore((s) => s.prevPrize)
  const setPrevPrize = useGameStore((s) => s.setPrevPrize)
  // const setBackupPrize = useGameStore((s) => s.setBackupPrize)
  const score = useGameStore((s) => s.score)

  const stockData = useSettingsCheck(data?.settings)
  const prizeData = useLocaleCheck(data?.prize)
  const gameOverData = useLocaleCheck(data?.["game-over"])

  const wonPrevPrize =
    !checkTriesLeft() &&
    prize === PrizeKeys.Lost &&
    prevPrize !== PrizeKeys.Lost

  const bronzeThreshold = parseInt(stockData?.bronzeThreshold, 10) || 1
  const bronzeStock = parseInt(stockData?.play_3, 10) > 0
  const bronzeAward = bronzeStock && score >= bronzeThreshold

  const silverThreshold = parseInt(stockData?.silverThreshold, 10) || 2
  const silverStock = parseInt(stockData?.play_2, 10) > 0
  const silverAward = silverStock && score >= silverThreshold

  const goldThreshold = parseInt(stockData?.goldThreshold, 10) || 3
  const goldStock = parseInt(stockData?.play_1, 10) > 0
  const goldAward = goldStock && score >= goldThreshold

  const lostAward = score >= 0 && score < bronzeThreshold

  function prevScore() {
    if (prevPrize === PrizeKeys.Bronze) {
      return bronzeThreshold
    } else if (prevPrize === PrizeKeys.Silver) {
      return silverThreshold
    } else {
      return goldThreshold
    }
  }

  async function endApi() {
    const points = wonPrevPrize ? prevScore() : score

    try {
      const res = await getApiEnd(id, points)
      process.env.NODE_ENV === "development" &&
        console.log(res?.data?.data?.prize)
      // TODO: update this when stock set up properly
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function prizeSetter() {
    if (!prize) {
      setPrevPrize(PrizeKeys.Lost)
    }

    switch (true) {
      case goldAward:
        setPrize(PrizeKeys.Gold)
        setPrevPrize(PrizeKeys.Gold)
        break
      case silverAward:
        setPrize(PrizeKeys.Silver)
        prevPrize !== PrizeKeys.Gold && setPrevPrize(PrizeKeys.Silver)
        break
      case bronzeAward:
        setPrize(PrizeKeys.Bronze)
        prevPrize !== PrizeKeys.Silver &&
          prevPrize !== PrizeKeys.Gold &&
          setPrevPrize(PrizeKeys.Bronze)
        break
      case lostAward:
        setPrize(PrizeKeys.Lost)
        break
      default:
        return
    }
  }

  React.useEffect(() => {
    endApi()
  }, [])

  React.useEffect(() => {
    prizeSetter()
  }, [loading, prize])

  const wonKey =
    prize && prize !== PrizeKeys.Lost
      ? prize?.toLowerCase()
      : PrizeKeys.Bronze.toLowerCase()

  const prevWonKey =
    prevPrize && prevPrize !== PrizeKeys.Lost
      ? prevPrize?.toLowerCase()
      : PrizeKeys.Bronze.toLowerCase()

  const wonText = prizeData?.[wonKey!]?.text
  const wonImage = prizeData?.[wonKey!]?.image.name
  const prevWonImage = prizeData?.[prevWonKey!]?.image.name

  if (!id) {
    return <Navigate to="/" replace />
  }

  return (
    <StyledPrize className="flex" style={{ opacity: loading ? 0 : 1 }}>
      {prize !== PrizeKeys.Lost ? (
        <Layout image={wonImage} text={wonText} title={prizeData?.title} />
      ) : wonPrevPrize ? (
        <Layout
          image={prevWonImage}
          text={gameOverData?.section[0].text}
          title={gameOverData?.section[0].title}
        />
      ) : (
        <Layout
          text={gameOverData?.section[1].text}
          title={gameOverData?.section[1].title}
          lost
        />
      )}
    </StyledPrize>
  )
}
