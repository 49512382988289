import styled from "styled-components"
import keyholeImage from "../../images/keyhole.png"

const StyledKeyhole = styled.div`
  background: var(--off-white);
  position: relative;
  width: 100%;
  padding-top: 166.666667%;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  img {
    user-select: none;
    pointer-events: none;
  }

  .keyhole__keyhole {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 50px 0px 50px rgba(0, 0, 0, 0.5) inset;
    z-index: 2;
  }

  .keyhole__shadow {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 1s ease;
  }

  &.flipped {
    .keyhole__shadow {
      opacity: 0.65 !important;
    }
  }
`

interface Props {
  className?: string
  shadowImage: string
  index: number
  id: number
  func: (e: any) => void
}

export default function Keyhole({
  className,
  shadowImage,
  index,
  id,
  func,
}: Props) {
  return (
    <StyledKeyhole
      className={`${className} keyhole`}
      data-id={id}
      data-index={index}
      onClick={(e) => func(e)}
    >
      <img alt="" src={keyholeImage} className="keyhole__keyhole" />
      <img alt="" src={shadowImage} className="keyhole__shadow" />
    </StyledKeyhole>
  )
}
