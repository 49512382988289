/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import styled from "styled-components"
import Keyhole from "./Keyhole"
import RedHerring from "./RedHerring"
import { useGameStore } from "../../state"
import { getCookies, setCookiesForTomorrow } from "../../utils/cookies"
import { useLoading } from "../../utils/common/useLoading"
import { Navigate, useNavigate } from "react-router-dom"
import { useShadows } from "../../shadows"
import { getApiStart } from "../../utils/api"

const StyledPlay = styled.div`
  min-height: var(--100vh);
  flex-direction: column;

  h3 {
    font-size: 1.5rem;
  }

  .game__grid {
    background: var(--background);
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(3, 166px);
    gap: 10px;
    transition: all 0.8s ease;
  }
`

const StyledCountIn = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;

  h1 {
    font-size: 3rem;
    text-shadow: 0px 0px 10px var(--background);
  }
`

export default function Play() {
  const open = useGameStore((s) => s.open)
  const time = useGameStore((s) => s.time)
  const setId = useGameStore((s) => s.setId)
  const locale = useGameStore((s) => s.locale)
  const redHerring = useGameStore((s) => s.difficulty)
  const score = useGameStore((s) => s.score)
  const setScore = useGameStore((s) => s.setScore)
  const tries = useGameStore((s) => s.tries)
  const [flipped, setFlipped] = React.useState<number[]>([])
  const [matched, setMatched] = React.useState<number[]>([])
  const [prizeOne, setPrizeOne] = React.useState<number | null>(null)
  const [gameOver, setGameOver] = React.useState<boolean>(false)
  const [countIn, setCountIn] = React.useState<number>(3)
  const [timer, setTimer] = React.useState<number>(time)
  const [swipe, setSwipe] = React.useState<boolean>(false)
  const loading = useLoading()
  const navigate = useNavigate()
  const shadows = useShadows()

  function resetFlipped() {
    setTimeout(() => {
      setFlipped([])
    }, 500)
  }

  function resetAll() {
    setFlipped([])
    setMatched([])
    setScore(0)
    setPrizeOne(null)
  }

  async function startApi() {
    try {
      const res = await getApiStart(locale, getCookies())
      setId(res?.data?.data?.id)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (!loading) {
      setCookiesForTomorrow(tries)
      resetAll()
      startApi()
    }
  }, [loading])

  function handleKeyholeClick(e: any) {
    const index = parseInt(e.target.getAttribute("data-index"))
    const id = parseInt(e.target.getAttribute("data-id"))

    if (getCookies() === 0) {
      return
    } else if (countIn > 0) {
      return
    } else if (flipped.includes(index)) {
      return
    } else if (redHerring && id === 99) {
      setFlipped((prev) => prev.concat(index))
      setSwipe(true)
      setTimeout(() => {
        resetAll()
        setSwipe(false)
      }, 500)
    } else if (!prizeOne) {
      setFlipped([index])
      setPrizeOne(id)
    } else if (prizeOne !== id) {
      setFlipped((prev) => prev.concat(index))
      setPrizeOne(null)
      resetFlipped()
    } else if (prizeOne === id) {
      setPrizeOne(null)
      setScore(score + 1)
      setMatched((prev) => prev.concat(id))
      setFlipped((prev) => prev.concat(index))
    }
  }

  React.useEffect(() => {
    const timeout = setInterval(() => {
      if (!loading) {
        countIn > -1 && setCountIn(countIn - 1)
      }
    }, 1000)
    return () => clearInterval(timeout)
  }, [loading, countIn])

  React.useEffect(() => {
    const timeout = setInterval(() => {
      if (!loading && countIn <= 0) {
        timer > 0 && setTimer(timer - 1)
      }
    }, 1000)
    return () => clearInterval(timeout)
  }, [loading, timer, countIn])

  React.useEffect(() => {
    if (matched.length === 4) {
      setTimer(0)
    }
  }, [matched])

  React.useEffect(() => {
    if (timer === 0) {
      setGameOver(true)

      setTimeout(() => {
        navigate("/prize")
      }, 1000)
    }
  }, [timer])

  if (!open) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      <StyledPlay className="flex">
        <div className="game__timer">
          <h3>{timer}</h3>
        </div>
        <div
          className="game__grid"
          style={{
            opacity: loading ? 0 : 1,
            transform: `scale(${gameOver ? 0 : 1}) rotate(${
              gameOver ? 360 : 0
            }deg)`,
          }}
        >
          {shadows?.map((shadow, i) => (
            <Keyhole
              key={i}
              className={`${
                flipped.includes(i) || matched.includes(shadow.id)
                  ? "flipped"
                  : ""
              }`}
              index={i}
              id={shadow.id}
              shadowImage={shadow.image}
              func={handleKeyholeClick}
            />
          ))}
        </div>
      </StyledPlay>
      {countIn > -1 && (
        <StyledCountIn dir="ltr">
          <h1>{countIn === 0 ? "GO!" : countIn}</h1>
        </StyledCountIn>
      )}
      {swipe && <RedHerring />}
    </>
  )
}
