import React from "react"

export const useLoading = (delay: number = 250) => {
  const [loading, setLoading] = React.useState<boolean>(true)

  function delayLoading() {
    setTimeout(() => {
      setLoading(false)
    }, delay)
  }

  React.useEffect(() => {
    if (document.readyState === "complete") {
      setLoading(false)
    } else {
      window.addEventListener("load", delayLoading)
      return () => window.removeEventListener("load", delayLoading)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading
}
