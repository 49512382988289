import React from "react"
import Portal from "../common/Portal"
import Popup from "../common/Popup"
import PlayAgainButton from "./PlayAgainButton"
import { useGameStore } from "../../state"
import { imageApi } from "../../utils/api"
import AjaxButton from "./AjaxButton"
import TermsAndConditions from "../common/TermsAndConditions"
import lostImage from "../../images/red-herring.png"
import ContinueShoppingButton from "../common/ContinueShoppingButton"
import { checkTriesLeft } from "../../utils/cookies"
import Redirect from "./Redirect"

interface Props {
  lost?: boolean
  image?: string
  text?: string
  title?: string
}

export default function Layout({ lost, image, text, title }: Props) {
  const [redirect, setRedirect] = React.useState<boolean>(false)
  const score = useGameStore((s) => s.score)
  const textWithScore = text?.replace("{score}", `${score}`)

  return (
    <Portal where="portal">
      <Popup>
        <h1>{title}</h1>
        <img
          alt={title}
          src={image ? imageApi(image!) : lostImage}
          style={{ maxHeight: 200, width: "unset", maxWidth: "100%" }}
        />
        <p>{textWithScore}</p>
        {!lost && <AjaxButton setRedirect={setRedirect} />}
        {checkTriesLeft() && <PlayAgainButton />}
        {(lost || !checkTriesLeft()) && <ContinueShoppingButton />}
        <TermsAndConditions black />
      </Popup>
      {redirect && <Redirect image={image ? imageApi(image!) : lostImage} />}
    </Portal>
  )
}
