import Cookies from "js-cookie"

let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(0)
tomorrow.setMinutes(0)
tomorrow.setMilliseconds(0)

enum CookieKey {
  Key = "gameplays",
}

export function setCookiesForTomorrow(tries?: number | string) {
  const cookie = Cookies.get(CookieKey.Key)

  if (cookie) {
    Cookies.set(CookieKey.Key, `${parseInt(cookie, 10) - 1}`, {
      expires: tomorrow,
    })
  } else if (!cookie) {
    Cookies.set(CookieKey.Key, `${tries || 3}`, { expires: tomorrow })
  }
}

export function getCookies() {
  return parseInt(`${Cookies.get(CookieKey.Key)}`, 10)
}

export function resetCookiesCheat() {
  let input = ""
  const keycode =
    "ArrowUpArrowDownArrowLeftArrowRightArrowUpArrowDownArrowLeftArrowRight"

  document.addEventListener("keydown", (e) => {
    input += e.code
    if (input === keycode) {
      return Cookies.set(CookieKey.Key, "99", { expires: tomorrow })
    }
    if (!keycode.indexOf(input)) return
    input = e.code
  })
}

export const checkTriesLeft = () => {
  return getCookies() > 1 || isNaN(getCookies())
}
