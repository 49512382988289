import React from "react"
import { useGameStore } from "../../state"
import {
  useLocaleCheck,
  useSettingsCheck,
} from "../../utils/localisation/useLocaleCheck"

interface Props {
  setRedirect: React.Dispatch<React.SetStateAction<boolean>>
}

export default function AjaxButton({ setRedirect }: Props) {
  const ref = React.useRef<HTMLFormElement>(null!)
  const data = useGameStore((s) => s.data)
  const score = useGameStore((s) => s.score)
  const prize = useGameStore((s) => s.prize)
  const settingsData = useSettingsCheck(data?.settings)
  const sharedData = useLocaleCheck(data?.shared)

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setRedirect(true)

    setTimeout(() => {
      ref.current?.submit()
    }, 3000)
  }

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit}
      action={settingsData?.addToBag}
      method="post"
      style={{ width: "100%" }}
    >
      <input type="hidden" name="userScore" value={score} />
      <input type="hidden" name="userAttempts" value={1} />
      <input type="hidden" name="userPrize" value={prize!} />
      <button type="submit" className="button">
        {sharedData?.addToBag}
      </button>
    </form>
  )
}
