import { Link } from "react-router-dom"
import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"
import { getCookies } from "../../utils/cookies"

export default function PlayAgainButton() {
  const data = useGameStore((s) => s.data)
  const sharedData = useLocaleCheck(data?.shared)

  return (
    <Link to="/play" className="button">
      {sharedData?.playAgain}
      <span className="garamond small" style={{ display: "block" }}>
        {getCookies() - 1} {sharedData?.triesRemaining}
      </span>
    </Link>
  )
}
