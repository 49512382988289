import React from "react"
import styled from "styled-components"
import { gsap } from "../../utils/gsap"
import redHerringImage from "../../images/red-herring.png"

const StyledRedHerring = styled.div`
  position: fixed;
  z-index: 9999;
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

  .rh__inner {
    max-width: 340px;
    height: var(--100vh);
  }

  .rh__inner img {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.6));
  }
`

export default function RedHerring() {
  React.useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(".rh__inner img", {
        scale: 1.3,
        ease: "elastic.out(1, 0.5)",
        duration: 0.8,
      })
    })
    return () => ctx.revert()
  }, [])

  return (
    <StyledRedHerring className="cover flex">
      <div className="rh__inner flex">
        <img alt="" src={redHerringImage} />
      </div>
    </StyledRedHerring>
  )
}
