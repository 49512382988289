import React from "react"
import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"
import Portal from "../common/Portal"
import Popup from "../common/Popup"
import { Link } from "react-router-dom"
import { imageApi } from "../../utils/api"

export default function Instructions() {
  const [index, setIndex] = React.useState<"one" | "two">("one")
  const data = useGameStore((s) => s.data)
  const instructionsData = useLocaleCheck(data?.instructions)

  function handleContinue() {
    setIndex("two")
  }

  function handleBack() {
    setIndex("one")
  }

  return (
    <Portal where="portal">
      <Popup>
        <h1>{instructionsData?.title}</h1>
        <img alt="" src={imageApi(instructionsData?.[index]?.image.name)} />
        <p>{instructionsData?.[index]?.text}</p>
        {index === "one" ? (
          <>
            <button className="button" onClick={handleContinue}>
              {instructionsData?.[index]?.button}
            </button>
            <Link to="/play">{instructionsData?.skip}</Link>
          </>
        ) : (
          <>
            <Link to="/play" className="button">
              {instructionsData?.[index]?.button}
            </Link>
            <button onClick={handleBack}>{instructionsData?.back}</button>
          </>
        )}
      </Popup>
    </Portal>
  )
}
