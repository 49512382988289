import React from "react"
import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"

interface Props {
  black?: boolean
}

export default function TermsAndConditions({ black }: Props) {
  const data = useGameStore((s) => s.data)
  const sharedData = useLocaleCheck(data?.shared)
  const textShadow =
    "0px 5px 10px black, 0px 5px 10px black, 0px 5px 10px black, 0px 5px 10px black, 0px 5px 10px black, 0px 5px 10px black"

  return (
    <p
      className="garamond"
      style={{
        color: black ? "var(--black)" : "var(--off-white)",
        textShadow: black ? "" : textShadow,
      }}
    >
      {sharedData?.tcText}{" "}
      <a href={sharedData?.tcLinkAddress} target="_blank" rel="noreferrer">
        {sharedData?.tcLinkText}
      </a>
    </p>
  )
}
