import React from "react"
import styled from "styled-components"
import backgroundImage from "../../images/home-background.png"
import logoImage from "../../images/logo.png"
import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"
import Instructions from "./Instructions"
import TermsAndConditions from "../common/TermsAndConditions"
import { checkTriesLeft } from "../../utils/cookies"
import ContinueShoppingButton from "../common/ContinueShoppingButton"
import Notice from "./Notice"

const StyledHome = styled.div`
  position: relative;

  background: var(--black);
  min-height: var(--100vh);

  .home__background {
    overflow: hidden;
    z-index: 1;

    img {
      height: var(--100vh);
      width: unset;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .home__container {
    display: grid;
    height: var(--100vh);
    grid-template-rows: 1.6fr 1fr 1fr 1fr;
    justify-items: center;
  }

  .home__logo {
    width: 100%;
    max-width: 320px;
    align-self: end;

    /* img {
      filter: drop-shadow(0px 0px 5px black) drop-shadow(0px 0px 10px black)
        drop-shadow(0px 0px 5px black) drop-shadow(0px 0px 5px black);
    } */
  }

  .button {
    align-self: start;
    
  }
`

export default function Home() {
  const [instructions, setInstructions] = React.useState<boolean>(false)
  const data = useGameStore((s) => s.data)
  const open = useGameStore((s) => s.open)
  const homeData = useLocaleCheck(data?.["landing-page"])
  const closedData = useLocaleCheck(data?.block)
  const triesData = useLocaleCheck(data?.["game-over"])

  function handlePlayButton() {
    setInstructions(true)

    const musicEvent = new Event("playmusic")
    document.dispatchEvent(musicEvent)
  }

  return (
    <>
      <StyledHome className="flex">
        <div className="home__background cover">
          <img alt="" src={backgroundImage} />
        </div>
        <div className="container home__container">
          <div />
          <div className="home__logo">
            <img alt="Catch the shadows" src={logoImage} />
          </div>
          {checkTriesLeft() && open ? (
            <button onClick={handlePlayButton} className="button">
              {homeData?.button_text ?? "Play"}
            </button>
          ) : (
            <ContinueShoppingButton />
          )}
          <TermsAndConditions />
        </div>
      </StyledHome>
      {instructions && <Instructions />}
      {!open && (
        <Notice
          title={closedData?.game_closed_title}
          text={closedData?.game_closed_text}
        />
      )}
      {!checkTriesLeft() && (
        <Notice
          title={triesData?.section[2].title}
          text={triesData?.section[2].text}
        />
      )}
    </>
  )
}
