import React from "react"
import styled from "styled-components"
import { gsap, Flip } from "../../utils/gsap"
import floorImage from "../../images/floor-with-objects.png"
import doorLeftImage from "../../images/door-left.jpg"
import doorRightImage from "../../images/door-right.jpg"
import topDecorImage from "../../images/top-decor.png"

const StyledDecorations = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  pointer-events: none;

  .decor {
    position: absolute;
  }

  .decor__floor {
    bottom: -100px;
    left: 50%;
    width: 380px;
    transform: translateX(-50%);

    @media (min-height: 900px) {
      bottom: -0px;
    }

    @media (max-height: 700px) {
      bottom: -120px;
    }

    @media (max-height: 650px) {
      display: none;
    }
  }

  .decor__lights {
    top: 0;
    width: 380px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-height: 768px) {
      top: -50px;
    }

    @media (max-height: 650px) {
      display: none;
    }
  }

  .decor__doors {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 0px 1fr;

    &.open {
      grid-template-columns: 1fr 380px 1fr;
    }
  }

  .decor__door {
    position: relative;
    background: var(--background);
    height: var(--100vh);

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      height: var(--100vh);
      object-fit: contain;
      width: unset;
      box-shadow: -10px 0px 15px rgba(0, 0, 0, 0.4),
        10px 0px 15px rgba(0, 0, 0, 0.4);

      &.left {
        right: 0;
      }

      &.right {
        left: 0;
      }
    }
  }
`

interface Props {
  open?: boolean
}

export default function Decorations({ open = false }: Props) {
  const doors = React.useRef<HTMLDivElement>(null!)

  React.useEffect(() => {
    let ctx = gsap.context(() => {
      const state = Flip.getState(doors.current, {
        props: "gridTemplateColumns",
      })

      open
        ? doors.current.classList.add("open")
        : doors.current.classList.remove("open")

      Flip.from(state, {
        absolute: true,
        duration: 1,
        delay: 0.8,
      })
    })

    return () => ctx.revert()
  }, [open])

  return (
    <StyledDecorations style={{ zIndex: open ? 99 : 0 }} dir="ltr">
      <img alt="" src={topDecorImage} className="decor decor__lights" />
      <img alt="" src={floorImage} className="decor decor__floor" />
      <div ref={doors} className="decor__doors">
        <div className="decor__door">
          <img alt="" src={doorLeftImage} className="left" />
        </div>
        <div />
        <div className="decor__door">
          <img alt="" src={doorRightImage} className="right" />
        </div>
      </div>
    </StyledDecorations>
  )
}
