import { createGlobalStyle, css } from "styled-components"

export const global = css`
  :root {
    --black: #000;
    --white: #fff;

    --off-white: #e7d6c7;

    --background: #d7b595;

    --button: #a1d9cc;

    --100vh: calc(var(--vh) * 100);
  }

  html,
  body {
    font-family: "Mon", sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1.45;
    font-size: 18px;
    overflow: hidden;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  body {
    min-height: 100vh;
    min-height: var(--100vh);
    position: relative;
    width: 100%;
  }

  .garamond {
    font-family: "Cormorant Garamond", serif;
  }

  .olderika {
    font-family: "OldErika", sans-serif;
  }

  .App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh) * 100);
    transition: opacity 0.5s ease;
    background: var(--background);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: normal;
    font-family: "OldErika", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    line-height: 0.9;
  }

  h1,
  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: var(--font-size-xxl);

    @media (max-width: 768px) {
      font-size: var(--font-size-xl);
    }
  }

  h4 {
    font-size: var(--font-size-xl);
  }

  h5 {
    font-size: var(--font-size-lg);
  }

  p,
  h6,
  label,
  li {
    font-size: var(--font-size-base);

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .small {
    font-size: 0.9rem;
  }

  a,
  button,
  *[aria-label="button"],
  *[role="button"],
  .button {
    cursor: pointer;
  }

  figure {
    position: relative;

    img {
      object-fit: cover;
    }
  }

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit;
  }

  /* a:not(.button) {
    &:hover {
      text-decoration: underline;
    }
  } */

  button {
    background: none;
    border: none;
    display: inline;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    outline: none;
    cursor: pointer;
  }

  img,
  video,
  iframe {
    object-fit: cover;
    width: 100%;
    user-select: none;
    user-drag: none;
    display: block;
  }

  svg {
    fill: currentColor;
  }

  section {
    width: 100%;
    position: relative;
    padding: 80px 50px 180px;
    z-index: 2;

    @media (max-width: 500px) {
      padding: 50px 30px 150px;
    }

    &:not(:first-child) {
      margin-top: -100px;
    }

    &:last-child {
      padding: 80px 50px 80px;

      @media (max-width: 500px) {
        padding: 50px 30px 50px;
      }
    }
  }

  textarea,
  input.text,
  input[type="text"],
  input[type="email"] {
    border: 1px solid;
    border-radius: 1rem;
    padding: 10px;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 2px var(--pink);
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  .handwriting {
    font-family: var(--handwriting);
    font-weight: normal !important;
    font-size: 1.1em;
  }

  .border {
    background: var(--page-colour);
    border-radius: var(--font-size-lg);
    display: inline-block;
    padding: var(--font-size-lg);
  }

  .wrapper {
    max-width: 1320px;
    margin: auto;
  }

  .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .responsive {
    width: 100%;

    img {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text,
  .richtext {
    > * {
      margin: auto;
    }

    > *:not(:first-child) {
      margin: 1rem auto 0;
    }
  }

  .button {
    font-family: "OldErika";
    background: var(--off-white);
    text-align: center;
    padding: 8px 25px;
    display: block;
    border-radius: 25px;
    font-size: 1.3rem;
    box-shadow: 5px 5px 5px var(--black);
    min-width: 200px;
    transition: all 0.25s ease;
    line-height: 1.2;
    border: 1px solid;

    &:hover {
      transform: scale(1.05);
      box-shadow: 2px 2px 5px var(--black);
    }
  }

  .link {
    text-transform: uppercase;
    font-weight: bold;
    background: linear-gradient(to top, black 50%, transparent 50%);
    background-size: 200% 200%;
    background-position: 0% -2px;
    transition: 0.25s ease;
    padding: 0 1px;

    &:hover {
      background-position: 0% 100%;
      color: var(--lime);
      text-decoration: none;
    }
  }

  .link-alt {
    display: inline-block;
    transition: 0.25s ease;

    &:hover {
      transform: translateY(-0.15em);
    }
  }

  #portal__hover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
    z-index: 99;

    .hl__image {
      position: absolute;
      width: 350px;
      pointer-events: none;
      user-select: none;
      transition: transform 0.4s ease;
      border-radius: var(--font-size-lg);
      overflow: hidden;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }

  .arrow {
    &:hover {
      .linkarrow__arrow {
        transform: translateY(0.2em) rotate(360deg);
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
    padding: 1.5rem;
    position: relative;
    z-index: 2;
    text-align: center;
  }
`

export const GlobalStyles = createGlobalStyle`
  ${global}
`
