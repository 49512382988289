import React from "react"
import styled from "styled-components"
import PopupInner from "./PopupInner"
import { gsap } from "../../utils/gsap"

export const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  /* padding: 10px; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  overflow: scroll;
  background: rgba(0, 0, 0, 0.6);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: 650px) {
    align-items: center;
  }
`

interface Props {
  children?: React.ReactNode
}

export default function Popup({ children }: Props) {
  const ref = React.useRef<HTMLDivElement>(null!)

  React.useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        ref.current.children[0],
        { opacity: 0, rotateY: 720, scale: 0 },
        { opacity: 1, rotateY: 0, scale: 1, duration: 0.75 }
      )
    })
    return () => ctx.revert()
  }, [])

  return (
    <StyledPopup ref={ref}>
      <PopupInner>{children}</PopupInner>
    </StyledPopup>
  )
}
