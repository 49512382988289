import { useGameStore } from "../../state"

export const useLocaleCheck = (data: any) => {
  const lang = useGameStore((s) => s.lang)
  const locale = useGameStore((s) => s.locale)

  return data?.content[locale][lang]
}

export const useSettingsCheck = (data: any) => {
  const locale = useGameStore((s) => s.locale)

  return data?.content[locale]
}
