import React from "react"
import Popup from "../common/Popup"
import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"

interface Props {
  image?: string
}

export default function Redirect({ image }: Props) {
  const data = useGameStore((s) => s.data)
  const redirectData = useLocaleCheck(data?.redirect)

  return (
    <Popup>
      <h1>{redirectData?.redirectTitle}</h1>
      <img
        alt=""
        src={image}
        style={{ maxHeight: 200, width: "unset", maxWidth: "100%" }}
      />
      <p>{redirectData?.redirectText}</p>
    </Popup>
  )
}
