import axios from "axios"

export const getApiContent = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/content?game=${process.env.REACT_APP_GAME_ID}`
  )

  return res
}

export const getApiStart = async (locale: string, cookie: number) => {
  const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/start`, {
    game: process.env.REACT_APP_GAME_ID,
    country: locale,
    try: cookie,
  })

  return res
}

export const getApiEnd = async (id: string, score: number) => {
  const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/end`, {
    id,
    point: score,
  })

  return res
}

export const imageApi = (image: string) =>
  `${process.env.REACT_APP_IMAGES_URL}/${image}`
