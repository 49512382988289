import { useEffect, useState } from "react"

import shadow1 from "./images/shadow-1.png"
import shadow2 from "./images/shadow-2.png"
import shadow3 from "./images/shadow-3.png"
import shadow4 from "./images/shadow-4.png"
import shadowRedHerring from "./images/shadow-red.png"

type ShadowT = {
  id: number
  image: any
}

const shadows = [
  { id: 1, image: shadow1 },
  { id: 2, image: shadow2 },
  { id: 3, image: shadow3 },
  { id: 4, image: shadow4 },
]

const redHerring = [{ id: 99, image: shadowRedHerring }]

// export const shuffledShadows = [...shadows, ...shadows, ...redHerring]
//   .map((a) => ({ sort: Math.random(), value: a }))
//   .sort((a, b) => a.sort - b.sort)
//   .map((a) => a.value)

const shadowsToShuffle = [...shadows, ...shadows, ...redHerring]

function shuffleShadows(shadows: ShadowT[]) {
  return shadows
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)
}

export const useShadows = () => {
  const [shadows, setShadows] = useState<ShadowT[]>(null!)

  useEffect(() => {
    setShadows(shuffleShadows(shadowsToShuffle))
  }, [])

  return shadows
}
