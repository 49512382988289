import { useGameStore } from "../../state"
import { useLocaleCheck } from "../../utils/localisation/useLocaleCheck"

interface Props {
  text?: string
}

export default function ContinueShoppingButton({ text }: Props) {
  const data = useGameStore((s) => s.data)
  const sharedData = useLocaleCheck(data?.shared)

  return (
    <a
      href={sharedData?.continuShoppingLink}
      target="_blank"
      rel="noreferrer"
      className="button"
    >
      {text ?? sharedData?.continueShopping}
    </a>
  )
}
